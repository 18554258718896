.pando-overlay {
  @include border-radius;

  position: relative;
  width: auto;
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #eee;
  background: #fff;

  .h1,
  .h2,
  .h3,
  h1,
  h2,
  h3 {
    margin-top: 0;
  }
}

.pando-overlay-xs {
  max-width: 150px;
}
.pando-overlay-sm {
  max-width: 300px;
}
.pando-overlay-lg {
  max-width: 700px;
}
.pando-overlay-xl {
  max-width: 900px;
}

.pando-overlay-zoom-in {
  .pando-overlay {
    @include transition(all .2s ease-in-out 0s);

    transform: scale(.8);
    opacity: 0;
  }
  &.mfp-ready .pando-overlay {
    transform: scale(1);
    opacity: 1;
  }
  &.mfp-removing .pando-overlay {
    transform: scale(.8);
    opacity: 0;
  }
  &.mfp-bg {
    @include transition(opacity .3s ease-out 0s);

    opacity: .001;
  }
  &.mfp-ready.mfp-bg {
    opacity: .8;
  }
  &.mfp-removing.mfp-bg {
    opacity: 0;
  }
}
